import React from 'react';
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import styles from '../../assets/styles/Dashboard.module.css';
import { CiCircleQuestion } from "react-icons/ci";
import {logout} from '../../services/auth'
import NetworkStatus from './NetworkStatus';
import BatteryStatus from './BatteryStatus';
function Header() {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Function to open the modal
  const openModal = () => setIsModalOpen(true);

  // Function to close the modal
  const closeModal = () => setIsModalOpen(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleOpenFAQ = () => {
    // Replace the URL with the desired FAQ page URL
    const faqUrl = 'https://ambicam.com/faq';
    window.open(faqUrl, '_blank');
  };
  
  const handleOpenPrivacyPolicy = () => {
    // Replace the URL with the desired FAQ page URL
    const faqUrl = 'https://home.arcisai.io/privacypolicy';
    window.open(faqUrl, '_blank');
  };

  const handleOpenTermsAndConditions = () => {
    // Replace the URL with the desired FAQ page URL
    const faqUrl = 'https://home.arcisai.io/termsandconditions';
    window.open(faqUrl, '_blank');
  };

  const handleOpenRefundAndCancellation = () => {
    // Replace the URL with the desired FAQ page URL
    const faqUrl = 'https://home.arcisai.io/refundandcancellation';
    window.open(faqUrl, '_blank');
  };

  const handleLogout = async () => {
    try {
      const logoutResult = await logout();

      if (logoutResult.success) {
        localStorage.removeItem('isLoggedIn');
        localStorage.removeItem('token');
        console.log('logout', logoutResult);
        navigate('/'); // This should navigate to the homepage
      } else {
        console.error('Logout error:', logoutResult.message);
      }
    } catch (error) {
      console.error('Logout error:', error);
    }
  };
  
  return (
    <header className={styles.header}>
      <img src="ArcisAi.png" alt="Company Logo" className={styles.logo} />
      <div className={styles.headerActions}>
        <button aria-label="Notification" className={styles.iconButton}>
          <BatteryStatus className={styles.icon}/>
         
        </button>
        <button aria-label="Messages" className={styles.iconButton}>
          <NetworkStatus className={styles.icon}/>
        </button>
        <button aria-label="Profile" className={styles.iconButton} onClick={toggleDropdown}>
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/c67124c316db5ffb2d81c5c7649279a0a4cc90bea5fb108354f2ffae59e0a484?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1"
            alt="Profile Icon"
            className={styles.icon}
          />
        </button>
        {isDropdownOpen && (
          <div className={styles.dropdownMenu}>
            <ul>
              <li onClick={handleOpenFAQ}><a>FAQ</a></li>
              <li onClick={handleOpenPrivacyPolicy}><a>Privacy Policy</a></li>
              <li onClick={handleOpenTermsAndConditions}><a>Terms and Conditions</a></li>
              <li onClick={handleOpenRefundAndCancellation}><a>Refund and Cancellation</a></li>
              <li onClick={handleLogout}><a>Sign out</a></li>
            </ul>
          </div>
        )}  
        <button className={styles.addDeviceButton} onClick={openModal}>+ device</button>

        {isModalOpen && (
          <div className={styles.backdrop}>
            <div className={styles.modal}>
            <div className={styles.modalContent}>
              <h1 style={{ color: "black" }}>Add New Device</h1>
              <p style={{ color: "black", fontWeight: "lighter", marginTop: "-10px" }}>Give the description below to add your new camera.
                <form className={styles.form}>
                  <div className={styles.formGroup}>
                    <label htmlFor="deviceDescription" style={{ color: "black", fontWeight: "lighter" }}>Device Name:</label>
                    <input
                      id="deviceDescription"
                      type="text"
                      className={styles.input}
                    />
                    {/* <CiCircleQuestion className={styles.circle} /> */}
                    <span className={styles.helperText}>Find this ID in your email</span>
                  </div>


                  <div className={styles.formGroup}>
                    <label htmlFor="deviceDescription" style={{ color: "black", fontWeight: "lighter" }}>Device ID:</label>
                    <input
                      id="deviceDescription"
                      type="text"
                      className={styles.input}
                    />

                    {/* <CiCircleQuestion className={styles.circle} /> */}
                    
                    <span className={styles.helperText}>Find this ID in your email</span>
                  </div>


                  <div className={styles.buttonGroup}>
                    <button type="submit" className={styles.submitButton}>Save Device</button>
                    <button type="button" className={styles.cancelButton} onClick={closeModal}>Cancel</button>
                  </div>
                </form>
              </p>
            </div>
            </div>
          </div>
        )}
      </div>
    </header>
  );
}

export default Header;