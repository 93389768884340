import React from "react";

import styles from "../Settings.module.css";
function SimCardNetworkSetup({ deviceId }) {
  return (
    <div className={styles.notify}>
        <div
        className={styles.checkboxContainer}
        style={{ marginBottom: "8px",color:'#7F56D9' }}
      >
        <label style={{ fontSize: "18px", fontWeight: "bold" }}>4G Network Setup</label>
      </div>

      <div style={{ marginBottom: "8px", display: "flex", alignItems: "center" }}>
        <label
          htmlFor="imei"
          style={{ flex: "0 0 150px", fontWeight: "bold",marginRight:'16px' }}
        >
          IMEI
        </label>
        <input type="text" id="imei" value="" style={{
            border: "1px solid #ccc",
            padding: "6px",
            borderRadius: "4px",
            fontSize: "12px"
          }}/>
      </div>

      <div style={{ marginBottom: "8px", display: "flex", alignItems: "center" }}>
        <label
          htmlFor="iccid"
          style={{ flex: "0 0 150px", fontWeight: "bold",marginRight:'16px' }}
        >
          ICCID
        </label>
        <input
          type="text"
          id="iccid"
          value=""
          style={{
            border: "1px solid #ccc",
            padding: "6px",
            borderRadius: "4px",
            fontSize: "12px"
          }}
        />
      </div>

      <div style={{ marginBottom: "8px", display: "flex", alignItems: "center" }}>
        <label
          htmlFor="singleValue"
          style={{ flex: "0 0 150px", fontWeight: "bold",marginRight:'16px' }}
        >
          Signal Value(dBm)
        </label>
        <input
          type="text"
          id="singleValue"
          value=""
          style={{
            border: "1px solid #ccc",
            padding: "6px",
            borderRadius: "4px",
            fontSize: "12px"
          }}
        />
      </div>
      <div style={{ marginBottom: "8px", display: "flex", alignItems: "center" }}>
        <label
          htmlFor="outBandWidth"
          style={{ flex: "0 0 150px", fontWeight: "bold",marginRight:'16px' }}
        >
          OutBanWidth(dBm)
        </label>
        <input
          type="text"
          id="outBandWidth"
          value=""
          style={{
            border: "1px solid #ccc",
            padding: "6px",
            borderRadius: "4px",
            fontSize: "12px"
          }}
        />
      </div>

      <div>
        <button style={{
        backgroundColor: "#7F56D9",
        color: "white",
        padding: "10px 16px",
        border: "none",
        borderRadius: "4px",
        cursor: "pointer",
        fontWeight: "bold"
      }}>Save</button>
      </div>
    </div>
  );
}

export default SimCardNetworkSetup;
