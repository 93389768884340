import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from '../Settings.module.css';

function PrivacyMask_Setup({ deviceId, onSave }) {
  const handlePrivacyMask = async () => {
    console.log("privacy Mask");
  };
  return (
    <div className={styles.notify}>
      {/* IR Mode selection */}
      <div className={styles.checkboxContainer} style={{ display: "flex" }}>
        <input
          type="checkbox"
          //   checked={isDigitalChecked}
          //   onChange={handleDigitalCheckboxChange}
        />
        <p>Enable Privacy Mask</p>
      </div>
      <div>
        <select
          placeholder="Color"
          className={styles.selectInput}
          // value={irMode}
          // onChange={handleIrModeChange}
        >
          <option value="5aaf5a">green</option>
          <option value="000000">white</option>
          <option value="006ecf">blue</option>
        </select>
      </div>
      <div>
        <button
          colorScheme="blue"
          onClick={handlePrivacyMask}
          style={{ color: "Blue" }}
        >
          Apply
        </button>
      </div>
    </div>
  );
}

export default PrivacyMask_Setup;
