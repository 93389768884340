import axios from "axios";
import React, { useState, useRef, useEffect } from "react";
import styles from "../Settings.module.css";

function FaceDetection({ deviceId }) {
  const [isEnabled, setIsEnabled] = useState(false);
  const [alarmSound, setAlarmSound] = useState(false);
  const [spOSD, setspOSD] = useState(false);
  const [Sensitivity, setSensitivity] = useState();

  const [whiteLightAlarm, setWhiteLightAlarm] = useState(false);
  const [appMessagePush, setAppMessagePush] = useState(false);
  const [rtmpPush, setRtmpPush] = useState(false);
  const [ftpPush, setFtpPush] = useState(false);
  const [error, setError] = useState(null);
  const [detectLine, setDetectLine] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://adiance-portal-backend-7d9tj.ondigitalocean.app/p2p/getFace",
          {
            params: {
              deviceId: deviceId,
            },
          }
        );

        setIsEnabled(response.data.Enabled);
        setspOSD(response.data.spOSD);
        setSensitivity(response.data.Sensitivity);
        setRtmpPush(response.data.AlarmOut.RtmpPush.Enabled);
        setFtpPush(response.data.AlarmOut.FtpPush.Enabled);
        setAlarmSound(response.data.AlarmOut.AudioAlert.Enabled);
        setWhiteLightAlarm(response.data.AlarmOut.LightAlert.Enabled);
        setAppMessagePush(response.data.AlarmOut.AppPush.Enabled);
        setDetectLine(response.data.DetectAera);
      } catch (error) {
        setError(error);
      }
    };

    fetchData();
  }, [deviceId]);

  const handleSave = async () => {
    try {
      const response = await axios.put(
        `https://adiance-portal-backend-7d9tj.ondigitalocean.app/p2p/setFace`,
        {
          Enabled: isEnabled,
          spOSD: spOSD,
          Sensitivity: Sensitivity,
          AlarmOut: {
            AudioAlert: {
              Enabled: alarmSound,
            },
            LightAlert: {
              Enabled: whiteLightAlarm,
            },
            AppPush: {
              Enabled: appMessagePush,
            },
            RtmpPush: {
              Enabled: rtmpPush,
            },
            FtpPush: {
              Enabled: ftpPush,
            },
          },
        },
        {
          params: { deviceId: deviceId },
        }
      );

      console.log("Settings saved", response.data);
    } catch (error) {
      console.error("Error saving settings", error);
    }
  };
  return (
    <div className={styles.notify}>
    <div
        className={styles.checkboxContainer}
        style={{ marginBottom: "6px",color:'#7F56D9' }}
      >
        <label style={{ fontSize: "18px", fontWeight: "bold" }}> Face Detection</label>
      </div>


      

      <div style={{ marginBottom: "6px", display: "flex", alignItems: "center" }}>
        <label htmlFor="enabled" style={{ flex: "0 0 150px", fontWeight: "bold",marginRight:'16px' }}>
          Enabled
        </label>
        <input
          type="checkbox"
          id="enabled"
          onChange={(e) => setIsEnabled(e.target.checked)}
          style={{
            border: "1px solid #ccc",
            padding: "6px",
            borderRadius: "4px",
            fontSize: "12px"
          }}
        />
      </div>

      <div style={{ marginBottom: "6px", display: "flex", alignItems: "center" }}>
        <label htmlFor="alarmSound" style={{ flex: "0 0 150px", fontWeight: "bold",marginRight:'16px' }}>
          Enable Alarm Sound
        </label>
        <input
          type="checkbox"
          id="alarmSound"
          onChange={(e) => setAlarmSound(e.target.checked)}
          style={{
            border: "1px solid #ccc",
            padding: "6px",
            borderRadius: "4px",
            fontSize: "12px"
          }}
        />
      </div>


    
      <div style={{ marginBottom: "6px", display: "flex", alignItems: "center" }}>
        <label htmlFor="whiteLight"  style={{  flex: "0 0 150px", fontWeight: "bold",marginRight:'16px' }}>
          Enable White Light alarm sound
        </label>
        <input
          type="checkbox"
          id="whiteLightAlarm"
          onChange={(e) => setWhiteLightAlarm(e.target.checked)}
          style={{
            border: "1px solid #ccc",
            padding: "6px",
            borderRadius: "4px",
            fontSize: "12px"
          }}
        />
      </div>

      <div style={{ marginBottom: "6px", display: "flex", alignItems: "center" }}>
        <label htmlFor="messagePush"  style={{  flex: "0 0 150px", fontWeight: "bold",marginRight:'16px' }}>
          Enable app message push
        </label>
        <input
          type="checkbox"
          id="messagePush"
          onChange={(e) => setAppMessagePush(e.target.checked)}
          style={{
            border: "1px solid #ccc",
            padding: "6px",
            borderRadius: "4px",
            fontSize: "12px"
          }}
        />
      </div>

      <div style={{ marginBottom: "6px", display: "flex", alignItems: "center" }}>
        <label htmlFor="enableRTMPPush"  style={{ flex: "0 0 150px",  fontWeight: "bold",marginRight:'16px' }}>
          Enable RTMP push
        </label>
        <input
          type="checkbox"
          id="enableRTMPPush"
          onChange={(e) => setRtmpPush(e.target.checked)}
          style={{
            border: "1px solid #ccc",
            padding: "6px",
            borderRadius: "4px",
            fontSize: "12px"
          }}
        />
      </div>

      <div style={{ marginBottom: "6px", display: "flex", alignItems: "center" }}>
        <label htmlFor="enableFTPPush"  style={{  flex: "0 0 150px", fontWeight: "bold",marginRight:'16px' }}>
          Enable FTP push
        </label>
        <input
          type="checkbox"
          id="enableFTPPush"
          onChange={(e) => setFtpPush(e.target.checked)}
          style={{
            border: "1px solid #ccc",
            padding: "6px",
            borderRadius: "4px",
            fontSize: "12px"
          }}
        />
      </div>

      <div style={{ marginBottom: "6px", display: "flex", alignItems: "center" }}>
        <label htmlFor="spOSD"  style={{ flex: "0 0 150px",  fontWeight: "bold",marginRight:'16px' }}>
          Face Draw Region
        </label>
        <input
          type="checkbox"
          id="spOSD"
          onChange={(e) => setspOSD(e.target.checked)}
          style={{
            border: "1px solid #ccc",
            padding: "6px",
            borderRadius: "4px",
            fontSize: "12px"
          }}
        />
      </div>

      <div style={{ marginBottom: "6px", display: "flex", alignItems: "center" }}>
        <label  style={{  flex: "0 0 150px", fontWeight: "bold",marginRight:'16px' }} >Sensitivity Level</label>
        <select
          width="auto"
          value={Sensitivity}
          onChange={(e) => setSensitivity(Number(e.target.value))}
          style={{
            padding: "8px 12px",
            border: "1px solid #ccc",
            borderRadius: "4px",
            fontSize: "14px",
            color: "#333",
            backgroundColor: "#fff",
            // appearance: "none", // Remove the default arrow style
            // boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)", // Subtle shadow for depth
          }}
        >
          {Array.from({ length: 11 }, (_, i) => (
            <option key={i} value={i}>
              {i}
            </option>
          ))}
        </select>
      </div>

      <div >
      <button style={{
        backgroundColor: "#7F56D9",
        color: "white",
        padding: "10px 16px",
        border: "none",
        borderRadius: "4px",
        cursor: "pointer",
        fontWeight: "bold"
      }}  onClick={handleSave}>Save</button>
      </div>
    </div>
  );
}

export default FaceDetection;
