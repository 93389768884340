import React,{useEffect,useState} from 'react';
import DeviceCard from './DeviceCard';
import styles from './Dashboard.module.css';
import { getCustomerCameraList } from '../../services/getcamera';
import { logout as apiLogout } from '../../services/auth';
import DeviceComponent from '../SingleDevice/DeviceComponent';


const fetchData = async (page, updateOnlyStatus = false) => {
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const customerId = userDetails.customerid;
  let resultPerPage = 90;
  if (window.innerWidth > 1645) {
    resultPerPage = 120; // Set to 12 if the screen width is greater than 1645px
  }

  try {
    // Fetch status data
    const statusResponse = await fetch('https://adiance-portal-backend-7d9tj.ondigitalocean.app/status');
    const statusData = await statusResponse.json();

    if (!updateOnlyStatus) {
      // Fetch camera list data
      const result = await getCustomerCameraList(customerId, page, resultPerPage);
      if (result.success) {
        // Merge the status data with camera list data
        const updatedCameraList = result.cameras.map(camera => {
          const matchingStatus = statusData.proxies.find(proxy => 
            proxy.name.trim().toLowerCase() === camera.deviceid.trim().toLowerCase()
          );
          return {
            ...camera,
            status: matchingStatus && matchingStatus.status === 'online' ? 'connected' : 'disconnected', // Add status field or default to 'unknown'
            todayTrafficOut: matchingStatus && matchingStatus.todayTrafficOut,
            current_streaming: matchingStatus && matchingStatus.curConns,
            lastStartTime: matchingStatus && matchingStatus.lastStartTime,
            lastCloseTime: matchingStatus && matchingStatus.lastCloseTime,  
          };
        });
        return updatedCameraList;
      }
    }
  } catch (error) {
    console.error('Error fetching camera list:', error);
  }
  return [];
};

function DeviceList() {
  const [devices, setDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);

  useEffect(() => {
    const loadDevices = async () => {
      const data = await fetchData(1);
      setDevices(data);
    };

    console.log(devices);
    loadDevices();

    // Update status periodically
    const statusInterval = setInterval(() => {
      fetchData(1, true).then((data) => {
        setDevices((prevDevices) =>
          prevDevices.map((device) => {
            const updatedDevice = data.find((d) => d.deviceid === device.deviceid);
            return updatedDevice ? { ...device, status: updatedDevice.status } : device;
          })
        );
      });
    }, 5000);

    return () => clearInterval(statusInterval);
  }, []);

  const handleDeviceClick = (device) => {
    setSelectedDevice(device);
  };
  const handleGoBack = () => {
    setSelectedDevice(null); // Set selectedDevice to null to go back to the device list
  };
  return (
    <section className={styles.deviceListSection}>
      {selectedDevice ? (
        <DeviceComponent 
        url={selectedDevice.planname === "LIVE" ? 
          `https://${selectedDevice.deviceid}.torqueverse.dev/flv/live_ch0_0.flv?verify=a/b4Znt+OFGrYtmHw0T16Q==`
         : `https://media.arcisai.io:8443/hdl/${selectedDevice.planname}/RTSP-${selectedDevice.deviceid}.flv`}
         
        // url={`https://${selectedDevice.deviceid}.torqueverse.dev/flv/live_ch0_0.flv?verify=a/b4Znt+OFGrYtmHw0T16Q==`} 
        device={selectedDevice} goBack={handleGoBack} status={selectedDevice.status} />
      ) : (
        <>

      <div className={styles.contentHeader}>
        <div className={styles.titleSubtitleContainer}>
          <h1 className={styles.title}>Device</h1>
          <h2 className={styles.subtitle} >Grouping</h2>
        </div>
      </div>

      <p className={styles.description}>Get the list of all your accessible devices under your account</p>
      
      <div className={styles.deviceListDescription}>
        <p> </p>
        <div className={styles.deviceListActions}>
          <button aria-label="Filter" className={styles.iconButton}>
            <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/daccb479376868477348abf201e94bf0470a004610f0e9e8bc4134be6d058625?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1" alt="" />
          </button>
          <button aria-label="Sort" className={styles.iconButton}>
            <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/7f07270178e15fcf44e046fec7f48c0664856c0600e5dea0c681f4eec2cce40c?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1" alt="" />
          </button>
        </div>
      </div>
      <div className={styles.deviceGrid}>
        {devices.map(device => (
          <DeviceCard 
          key={device.deviceid}
            name={device.cameraname}
            serial={device.deviceid}
            status={device.status}
            lastSnapshot={'12 mins ago'}
            image={'https://cdn.builder.io/api/v1/image/assets/TEMP/defa8084f16f3d77ef8c8e959e418aeaac57dfe4712fb68ebdc3a61c0785f8f1?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1'}
            onClick={() => handleDeviceClick(device)}
          {...device} />
        ))}
      </div>
      </>
      )}
    </section>
  );
}

export default DeviceList;