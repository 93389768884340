import React, { useState, useEffect } from 'react';

const NetworkStatus = () => {
  const [networkStatus, setNetworkStatus] = useState('unknown');

  useEffect(() => {
    const updateNetworkStatus = () => {
      if ('connection' in navigator) {
        const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
        const effectiveType = connection.effectiveType;

        let status;
        switch (effectiveType) {
          case '5g':
            status = (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M2 20H2.01" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M7 20V16" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12 20V12" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M17 20V8" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M22 4V20" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            );
            break;
          case '4g':
            status = (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M2 20H2.01" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M7 20V16" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12 20V12" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M17 20V8" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            );
            break;
          case '3g':
            status = (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M2 20H2.01" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M7 20V16" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12 20V12" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            );
            break;
          case '2g':
          case 'slow-2g':
            status = (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M2 20H2.01" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M7 20V16" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            );
            break;
          default:
            status = (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M2 20H2.01" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            );
        }

        setNetworkStatus(status);
      } else {
        setNetworkStatus( <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M2 20H2.01" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M7 20V16" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M12 20V12" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M17 20V8" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>);
      }
    };

    updateNetworkStatus();

    // Listen for changes in the connection type
    if (navigator.connection) {
      navigator.connection.addEventListener('change', updateNetworkStatus);
    }

    // Cleanup event listener on component unmount
    return () => {
      if (navigator.connection) {
        navigator.connection.removeEventListener('change', updateNetworkStatus);
      }
    };
  }, []);

  return (
    <div>
      {networkStatus}
    </div>
  );
};

export default NetworkStatus;
