import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import LoginPage from './pages/LoginPage/LoginPage';
import SignUpPage from './pages/SignUpPage/SignUpPage';
import theme from './assets/styles/theme';
import AlertsTable from './pages/AlertPage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import RefundAndCancellation from './pages/RefundAndCancellation';
import TermsAndConditions from './pages/TermsAndConditions';
import Header from './components/Header/Header';
import Sidebar from './components/Sidebar/Sidebar';
import Dashboard from './pages/Dashboard/Dashboard';
import MultiScreenDesktop from './pages/MultiScreen/MultiScreen';
import AIEvents from './pages/Events/AIEvents';

function AppContent() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // Check if user is logged in (e.g., check local storage or API)
    const loggedInStatus = localStorage.getItem('isLoggedIn') === 'true';
    setIsLoggedIn(loggedInStatus);
  }, []);
  
  useEffect(() => {
    const removeCSSRules = () => {
      const styleSheets = document.styleSheets;

      for (let i = 0; i < styleSheets.length; i++) {
        const sheet = styleSheets[i];
        if (sheet.cssRules) {
          for (let j = sheet.cssRules.length - 1; j >= 0; j--) {
            const rule = sheet.cssRules[j];
            if (rule.selectorText === '.jessibuca-container') {
              sheet.deleteRule(j);
            }
          }
        }
      }
    };

    removeCSSRules();
  }, []);
  const showHeader = !['/', '/signup'].includes(location.pathname);
  const showSidebar = !['/', '/signup'].includes(location.pathname);

  
  return (
    <>
    
      {showHeader && <Header />}
      {showSidebar && <Sidebar />}
      <Routes>
     
        <Route path="/" element={isLoggedIn ? <Navigate to="/dashboard" /> : <LoginPage />} />
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/events" element={<AIEvents />} />
        <Route path="/dashboard" element={<Dashboard />} />
        {/* <Route path="/dash" element={< DashboardPage/>} /> */}
        <Route path="/multiple" element={<MultiScreenDesktop />} />
        <Route path="/alert" element={<AlertsTable />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/refundandcancellation" element={<RefundAndCancellation />} />
        <Route path="/termsandconditions" element={<TermsAndConditions />} />
      </Routes>
    </>
  );
}

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <AppContent />
      </Router>
    </ChakraProvider>
  );
}

export default App;
