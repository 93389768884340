import React, { useState, useEffect } from 'react';

const BatteryStatus = () => {
  const defaultSvg = (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M16 7H4C2.89543 7 2 7.89543 2 9V15C2 16.1046 2.89543 17 4 17H16C17.1046 17 18 16.1046 18 15V9C18 7.89543 17.1046 7 16 7Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M22 11V13" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M6 11V13" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M10 11V13" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M14 11V13" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );

  const [batteryStatus, setBatteryStatus] = useState(defaultSvg);

  useEffect(() => {
    if (navigator.getBattery) {
      const updateBatteryStatus = (battery) => {
        const level = battery.level * 100;
        let status;

        if (battery.charging) {
          status = (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M15 7H16C16.5304 7 17.0391 7.21071 17.4142 7.58579C17.7893 7.96086 18 8.46957 18 9V15C18 15.5304 17.7893 16.0391 17.4142 16.4142C17.0391 16.7893 16.5304 17 16 17H14" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M6 7H4C3.46957 7 2.96086 7.21071 2.58579 7.58579C2.21071 7.96086 2 8.46957 2 9V15C2 15.5304 2.21071 16.0391 2.58579 16.4142C2.96086 16.7893 3.46957 17 4 17H5" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M11 7L8 12H12L9 17" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M22 11V13" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          );      
        } else if (level > 80) {
          status = (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M16 7H4C2.89543 7 2 7.89543 2 9V15C2 16.1046 2.89543 17 4 17H16C17.1046 17 18 16.1046 18 15V9C18 7.89543 17.1046 7 16 7Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M22 11V13" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M6 11V13" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M10 11V13" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M14 11V13" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          );  
        } else if (level > 30) {
          status = (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M16 7H4C2.89543 7 2 7.89543 2 9V15C2 16.1046 2.89543 17 4 17H16C17.1046 17 18 16.1046 18 15V9C18 7.89543 17.1046 7 16 7Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M22 11V13" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M6 11V13" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M10 11V13" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          );  
        } else {
          status = (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M16 7H4C2.89543 7 2 7.89543 2 9V15C2 16.1046 2.89543 17 4 17H16C17.1046 17 18 16.1046 18 15V9C18 7.89543 17.1046 7 16 7Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M22 11V13" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M6 11V13" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          );  
        }

        setBatteryStatus(status);

        // Event listeners for updates
        battery.addEventListener('levelchange', () => updateBatteryStatus(battery));
        battery.addEventListener('chargingchange', () => updateBatteryStatus(battery));
      };

      navigator.getBattery().then((battery) => {
        updateBatteryStatus(battery);
      });

      // Cleanup event listeners on component unmount
      return () => {
        navigator.getBattery().then((battery) => {
          battery.removeEventListener('levelchange', updateBatteryStatus);
          battery.removeEventListener('chargingchange', updateBatteryStatus);
        });
      };
    }
  }, []);

  return (
    <div>
      {batteryStatus}
    </div>
  );
};

export default BatteryStatus;
