import React, { useEffect, useState } from "react";
import axios from "axios";

import styles from "../Settings.module.css";

function LocalSetup({ deviceId }) {
  const [networkInterface, setNetworkInterface] = useState("");
  const [dnsSetting, setDnsSetting] = useState("");
  const [macaddress, setMacAddress] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://adiance-portal-backend-7d9tj.ondigitalocean.app/p2p/networkInterface-setting",
          {
            params: {
              deviceId: deviceId,
            },
          }
        );
        setNetworkInterface(response.data.lan);
      } catch (error) {
        setError(error);
      }
    };

    fetchData();
  }, []); // Empty dependency array means this effect runs once after the initial render

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://adiance-portal-backend-7d9tj.ondigitalocean.app/p2p/dns-setting",
          {
            params: {
              deviceId: deviceId,
            },
          }
        );
        setDnsSetting(response.data);
      } catch (error) {
        setError(error);
      }
    };

    fetchData();
  }, []); // Empty dependency array means this effect runs once after the initial render

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://adiance-portal-backend-7d9tj.ondigitalocean.app/p2p/mac-address",
          {
            params: {
              deviceId: deviceId,
            },
          }
        );
        setMacAddress(response.data);
      } catch (error) {
        setError(error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className={styles.notify}>
      <div
        className={styles.checkboxContainer}
        style={{ marginBottom: "8px",color:'#7F56D9' }}
      >
        <label style={{ fontSize: "18px", fontWeight: "bold" }}>Local Setup</label>
      </div>

      <div style={{ marginBottom: "8px", display: "flex", alignItems: "center" }}>
        <label
          htmlFor="macaddress"
          style={{ flex: "0 0 150px", marginRight: "16px", fontWeight: "bold" }}
        >
          Mac Address
        </label>
        <input
          type="text"
          id="macaddress"
          style={{
            border: "1px solid #ccc",
            padding: "6px",
            borderRadius: "4px",
            fontSize: "12px"
          }}
          disabled
        />
      </div>
      <div style={{ display: "flex", alignItems: "center" ,marginBottom:'8px'}}>
        <label style={{ flex: "0 0 150px", marginRight: "16px", fontWeight: "bold" }}>IP Adapted</label>
        <label style={{ flex: "0 0 150px", marginRight: "16px" }}>
          <input type="checkbox" /> Open
        </label>
        <label style={{ flex: "0 0 150px", marginRight: "16px",  }}>
          <input type="checkbox" /> Close
        </label>
      </div>

      <div style={{ marginBottom: "8px", display: "flex", alignItems: "center" }}>
        <label style={{ flex: "0 0 150px", marginRight: "16px",fontWeight:'bold' }}>DHCP Switch</label>
        <label style={{ flex: "0 0 150px", marginRight: "16px" }}>
          <input type="checkbox" /> Open
        </label>
        <label style={{ flex: "0 0 150px", marginRight: "16px" }}>
          <input type="checkbox" /> Close
        </label>
      </div>

      <div style={{ marginBottom: "8px", display: "flex", alignItems: "center" }}>
        <label
          htmlFor="ipAddress"
          style={{ flex: "0 0 150px", marginRight: "16px",fontWeight:'bold' }}
        >
          IP Address
        </label>
        <input
          type="text"
          id="ipAddress"
          value={networkInterface.staticIP}
          style={{
            border: "1px solid #ccc",
            padding: "6px",
            borderRadius: "4px",
            fontSize: "12px"
          }}
          disabled
        />
      </div>

      <div style={{ marginBottom: "8px", display: "flex", alignItems: "center" }}>
        <label
          htmlFor="gateway"
          style={{ flex: "0 0 150px", marginRight: "16px",fontWeight:'bold' }}
        >
          Gateway
        </label>
        <input
          type="text"
          id="gateway"
          value={networkInterface.staticGateway}
          style={{
            border: "1px solid #ccc",
            padding: "6px",
            borderRadius: "4px",
            fontSize: "12px"
          }}
          disabled
        />
      </div>

      <div style={{ marginBottom: "8px", display: "flex", alignItems: "center" }}>
        <label
          htmlFor="netmask"
          style={{ flex: "0 0 150px", marginRight: "16px",fontWeight:'bold' }}
          >
          Netmask
        </label>
        <input
          type="text"
          id="netmask"
          value={networkInterface.staticNetmask}
          style={{
            border: "1px solid #ccc",
            padding: "6px",
            borderRadius: "4px",
            fontSize: "12px"
          }}
          disabled
        />
      </div>

      <div style={{ marginBottom: "8px", display: "flex", alignItems: "center" }}>
        <label
          htmlFor="preferedDNS"
          style={{ flex: "0 0 150px", marginRight: "16px",fontWeight:'bold' }}
        >
          Prefered DNS
        </label>
        <input
          type="text"
          id="preferedDNS"
          value={dnsSetting.preferredDns}
          style={{
            border: "1px solid #ccc",
            padding: "6px",
            borderRadius: "4px",
            fontSize: "12px"
          }}
          disabled
        />
      </div>

      <div style={{ marginBottom: "8px", display: "flex", alignItems: "center" }}>
        <label
          htmlFor="alternateDNS"
          style={{ flex: "0 0 150px", marginRight: "16px",fontWeight:'bold' }}>
          Alternate DNS
        </label>
        <input
          type="text"
          id="alternateDNS"
          value={dnsSetting.staticAlternateDns}
          style={{
            border: "1px solid #ccc",
            padding: "6px",
            borderRadius: "4px",
            fontSize: "12px"
          }}
          disabled
        />
      </div>


      <div style={{ marginBottom: "8px", display: "flex", alignItems: "center" }}>
        <label
          htmlFor="port"
          style={{ flex: "0 0 150px", marginRight: "16px",fontWeight:'bold' }}>
        
          Port
        </label>
        <input
          type="text"
          id="port"
          value="80"
          style={{
            border: "1px solid #ccc",
            padding: "6px",
            borderRadius: "4px",
            fontSize: "12px"
          }}
          disabled
        />
      </div>

      <div style={{ marginBottom: "8px", display: "flex", alignItems: "center" }}>
        <button  style={{
        backgroundColor: "#7F56D9",
        color: "white",
        padding: "10px 16px",
        border: "none",
        borderRadius: "4px",
        cursor: "pointer",
        fontWeight: "bold"
      }}>Save</button>
      </div>
    </div>
  );
}

export default LocalSetup;
