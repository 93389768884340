import React from "react";

import axios from "axios";

import styles from "../Settings.module.css";
function FTPSetup({ deviceId }) {
  return (
    <div className={styles.notify}>
       <div
        className={styles.checkboxContainer}
        style={{ marginBottom: "8px",color:'#7F56D9' }}
      >
        
        <label style={{ fontSize: "18px", fontWeight: "bold" }}>FTP Setup</label>
      </div>

      <div style={{ marginBottom: "8px", display: "flex", alignItems: "center" }}>
        <label
          htmlFor="ftpSwitch"
          style={{ flex: "0 0 150px", fontWeight: "bold",marginRight:'16px' }}>
          FTP Switch
        </label>
        <input type="checkbox" id="ftpSwitch" style={{
            border: "1px solid #ccc",
            padding: "6px",
            borderRadius: "4px",
            fontSize: "12px"
          }} />
      </div>

      <div style={{ marginBottom: "8px", display: "flex", alignItems: "center" }}>
        <label
          htmlFor="ftpSyncSwitch"
          style={{ flex: "0 0 150px", fontWeight: "bold",marginRight:'16px' }}>
          FTP Synchronization Switch
        </label>
        <input
          type="checkbox"
          id="ftpSyncSwitch"
          style={{
            border: "1px solid #ccc",
            padding: "6px",
            borderRadius: "4px",
            fontSize: "12px"
          }}
        />
      </div>

      <div style={{ marginBottom: "8px", display: "flex", alignItems: "center" }}>
        <label
          htmlFor="ftpSyncType"
          style={{ flex: "0 0 150px", fontWeight: "bold",marginRight:'16px' }}>
          FTP Synchronization Type
        </label>
        <select name="ftpSyncType" id="ftpSyncType"
            style={{
              padding: "1px 2px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              fontSize: "14px",
              color: "#333",
              backgroundColor: "#fff",
              // appearance: "none", // Remove the default arrow style
              // boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)", // Subtle shadow for depth
          }}>
           <option value="" disabled selected>
            Select FTP Synchronization Type
          </option>
          <option value="Sync all recordings by time(days)">
            Sync all recordings by time(days)
          </option>

          <option value="Sync all recordings">Sync all recordings</option>
        </select>
      </div>

      <div style={{ marginBottom: "8px", display: "flex", alignItems: "center" }}>
        <label
          htmlFor="ftpCustomSyncTime"
          style={{ flex: "0 0 150px", fontWeight: "bold",marginRight:'16px' }}
        >
          FTP Custom Synchronization Time
        </label>
        <input
          type="text"
          id="ftpCustomSyncTime"
          value=""
          style={{
            border: "1px solid #ccc",
            padding: "6px",
            borderRadius: "4px",
            fontSize: "12px"
          }}
        />
      </div>

      <div style={{ marginBottom: "8px", display: "flex", alignItems: "center" }}>
        <label
          htmlFor="ftpScheduleSwitch" style={{ flex: "0 0 150px", fontWeight: "bold",marginRight:'16px' }}
        >
          FTP Schedule Switch
        </label>
        <input
          type="checkbox"
          id="ftpScheduleSwitch"
          style={{
            border: "1px solid #ccc",
            padding: "6px",
            borderRadius: "4px",
            fontSize: "12px"
          }}
        />
      </div>

      <div style={{ marginBottom: "8px", display: "flex", alignItems: "center" }}>
        <label
          htmlFor="ftpPort"
          style={{ flex: "0 0 150px", fontWeight: "bold",marginRight:'16px' }}
        >
          FTP Port
        </label>
        <input
          type="text"
          id="ftpPort"
          value=""
          style={{
            border: "1px solid #ccc",
            padding: "6px",
            borderRadius: "4px",
            fontSize: "12px"
          }}
        />
      </div>

      <div style={{ marginBottom: "8px", display: "flex", alignItems: "center" }}>
        <label
          htmlFor="ftpUserName"
          style={{ flex: "0 0 150px", fontWeight: "bold",marginRight:'16px' }}
        >
          FTP UserName
        </label>
        <input
          type="text"
          id="ftpUserName"
          value=""
          style={{
            border: "1px solid #ccc",
            padding: "6px",
            borderRadius: "4px",
            fontSize: "12px"
          }}
        />
      </div>

      <div style={{ marginBottom: "8px", display: "flex", alignItems: "center" }}>
        <label
          htmlFor="ftpPassword"
          style={{ flex: "0 0 150px", fontWeight: "bold",marginRight:'16px' }}
        >
          FTP Password
        </label>
        <input
          type="text"
          id="ftpPassword"
          value=""
          style={{
            border: "1px solid #ccc",
            padding: "6px",
            borderRadius: "4px",
            fontSize: "12px"
          }}
        />
      </div>

      <div style={{ marginBottom: "8px", display: "flex", alignItems: "center" }}>
        <label
          htmlFor="ftpServerIP"
          style={{ flex: "0 0 150px", fontWeight: "bold",marginRight:'16px' }}
        >
          FTP Server IP(domain name)
        </label>
        <input
          type="text"
          id="ftpServerIP"
          value=""
          style={{
            border: "1px solid #ccc",
            padding: "6px",
            borderRadius: "4px",
            fontSize: "12px"
          }}
        />
      </div>

      <div style={{ marginBottom: "8px", display: "flex", alignItems: "center" }}>
        <label
          htmlFor="ftpUpload"
          style={{ flex: "0 0 150px", fontWeight: "bold",marginRight:'16px' }}
        >
          FTP upload directory
        </label>
        <input
          type="text"
          id="ftpUpload"
          value=""
          style={{
            border: "1px solid #ccc",
            padding: "6px",
            borderRadius: "4px",
            fontSize: "12px"
          }}
        />
      </div>

      <div style={{ marginBottom: "8px", display: "flex", alignItems: "center" }}>
        <label
          htmlFor="ftpUploadVideoPath"
          style={{ flex: "0 0 150px", fontWeight: "bold",marginRight:'16px' }}
        >
          FTP upload video path
        </label>
        <input
          type="text"
          id="ftpUploadVideoPath"
          value=""
          style={{
            border: "1px solid #ccc",
            padding: "6px",
            borderRadius: "4px",
            fontSize: "12px"
          }}
        />
      </div>

      <div stylstyle={{ marginBottom: "8px", display: "flex", alignItems: "center" }}>
        <label
          htmlFor="ftpUploadPicturePath"
          style={{ flex: "0 0 150px", fontWeight: "bold",marginRight:'16px' }}
        >
          FTP Upload     Picture Path
        </label>
        <input
          type="text"
          id="ftpUploadPicturePath"
          value=""
          style={{
            border: "1px solid #ccc",
            padding: "6px",
            borderRadius: "4px",
            fontSize: "12px"
          }}
        />
      </div>

      <div style={{ marginBottom: "8px", display: "flex", alignItems: "center" }}>
        <label
          htmlFor="ftpUploadTimeInterval"
          style={{ flex: "0 0 150px", fontWeight: "bold",marginRight:'16px' }}
        >
          FTP upload time interval (in seconds)
        </label>
        <input
          type="text"
          id="ftpUploadTimeInterval"
          value=""
          style={{
            border: "1px solid #ccc",
            padding: "6px",
            borderRadius: "4px",
            fontSize: "12px"
          }}
        />
      </div>

      <div style={{ marginBottom: "8px", display: "flex", alignItems: "center" }}>
        <label
          htmlFor="ftpUploadWay"
          style={{ flex: "0 0 150px", fontWeight: "bold",marginRight:'16px' }}
        >
          FTP Upload Way
        </label>
        <select name="ftpUploadWay" id="ftpUploadWay"  style={{
              padding: "1px 2px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              fontSize: "14px",
              color: "#333",
              backgroundColor: "#fff",
              // appearance: "none", // Remove the default arrow style
              // boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)", // Subtle shadow for depth
          }}>
          <option value="" disabled selected>
            Select FTP Upload Way
          </option>
          <option value="Alarm">Alarm</option>
          <option value="Time">Time</option>
        </select>
      </div>

      <div style={{ marginBottom: "8px", display: "flex", alignItems: "center" }}>
        <label
          htmlFor="ftpUploadContent"
          style={{ flex: "0 0 150px", fontWeight: "bold",marginRight:'16px' }}
        >
          FTP Upload Content
        </label>
        <select name="ftpUploadContent" id="ftpUploadContent"  style={{
              padding: "1px 2px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              fontSize: "14px",
              color: "#333",
              backgroundColor: "#fff",
              // appearance: "none", // Remove the default arrow style
              // boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)", // Subtle shadow for depth
          }}>
          <option value="" disabled selected>
            Select FTP Upload Content
          </option>
          <option value="Upload Picture">Upload Picture</option>
          <option value="Upload Video">Upload Video</option>
          <option value="Picture and Video">Picture and Video</option>
        </select>
      </div>

      <div style={{ display: "flex" }}>
        <button style={{
        backgroundColor: "#fd0a54" ,
        color: "white",
        padding: "2px 6px",
        border: "none",
        borderRadius: "4px",
        cursor: "pointer",
        fontWeight: "bold"
      }}>TEST</button>

        <button
style={{
  backgroundColor: "#7F56D9" ,
  color: "white",
  padding: "4px 8px",
  border: "none",
  borderRadius: "4px",
  cursor: "pointer",
            fontWeight: "bold",
  marginLeft:'10px'
}}
        >
          SAVE
        </button>
      </div>
    </div>
  );
}

export default FTPSetup;
