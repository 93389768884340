import React, { useState } from 'react';
import styles from './EventCard.module.css';

const EventCard = ({ title, deviceId, length,name, source,imageUrl,TimeStart,TimeEnd,onUrlSelect  }) => {
  // console.log(TimeStart)
  const [selectedUrl, setSelectedUrl] = useState(null);

  const handlePlay = (item) => {
    setSelectedUrl(item);
    if (onUrlSelect) {
      onUrlSelect(item); // Call the callback function passed from the parent
    }
  };

  return (
    
    <article className={styles.eventCard} 
    
    onClick={() => handlePlay(`https://${deviceId}.torqueverse.dev/flv/pb?pb_${TimeStart}_${TimeEnd}_ch0_0.flv?verify=a/b4Znt+OFGrYtmHw0T16Q==`)}
    >
      <span className={styles.duration}>{length}</span>
      <img src={imageUrl} alt={`Event at ${title}`} className={styles.eventImage} />
      <div className={styles.eventInfo}>
        <h3 className={styles.eventTitle}>{title}</h3>
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/06330525eb2816a625fd05da8911e37d85a9e2bf2cdf7d384a4885dec9c65e9a?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1" alt="" className={styles.infoIcon} />
      </div>
      <div className={styles.deviceInfo}>
        <span className={styles.deviceId}>{deviceId}</span>
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/cc3664f3d591a9e1b6a22eaf0300eae9db35b233a32b7a40c23f9d960ee58085?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1" alt="" className={styles.statusIcon} />
        <span className={styles.deviceId}> - {name}</span>
      </div>
      <p className={styles.eventLength}> Time: {new Date(TimeStart * 1000).toLocaleString('en-US', { timeZone: 'GMT' })} </p>
      <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/5d62710cce8a4ec549ff248e0f791cb7705afd2da880c42d9f39b0746ef1f121?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1" alt="Play" className={styles.playButton} />
      <span className={styles.edgeSource}>{source} Event</span>
    </article>
  );
};


export default EventCard;