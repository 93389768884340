import React, { useState,useEffect } from 'react';
import styles from './Settings.module.css';
import { IoSettingsOutline, IoWifi } from "react-icons/io5";
import { LuBadgeHelp } from "react-icons/lu";
import { MdContentCopy } from "react-icons/md";
import axios from 'axios';

function General({ device ,onSave}) {
  const [bEnable, setBEnable] = useState(true);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  
  const fetchAlertSettings = async () => {
      try {
        setLoading(true); 
        setError(null); 
        const response = await axios.get('https://adiance-portal-backend-7d9tj.ondigitalocean.app/p2p/getAlertSetting', {
          params: { deviceId: device.deviceid }
        });

        setBEnable(response.data.bEnable); 
      } catch (error) {
        setError('Error fetching alert settings');
        console.error(error);
      } finally {
        setLoading(false); 
      }
    };

  
  const setAlertSetting = async (newBEnable) => {
    try {
      setLoading(true); 
      setError(null); 

      await axios.put('https://adiance-portal-backend-7d9tj.ondigitalocean.app/p2p/setAlertSetting', {
        bEnable: newBEnable,
      }, {
        params: { deviceId: device.deviceid },
      });

      console.log('Notification setting updated successfully');
    } catch (err) {
      setError('Failed to update the notification settings');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  
  const handleToggle = () => {
    const newBEnable = !bEnable;
    setBEnable(newBEnable);
    setAlertSetting(newBEnable); 
  };

  useEffect(() => {
    fetchAlertSettings(); 
  }, [device.deviceid]); 


    const reboot = async () => {
      try {
        setLoading(true); 
        setError(null); 

      await axios.put('https://adiance-portal-backend-7d9tj.ondigitalocean.app/p2p/reboot', {
          deviceId: device.deviceid 
        });

        console.log('Reboot successfully');
      } catch (err) {
        setError('Failed to Reboot');
        console.error(err);
      } finally {
        setLoading(false); 
      }
    };

    return(
        <div className={styles.notify}>
          {/* <p>Notification</p> */}

          <div className={styles.notificationItem}>
          <p>AI-Notification</p>
          <div className={styles.toggleSwitch}>
            <input
            type="checkbox"
            id="motionDetectionAlert"
            className={styles.toggleInput}
            checked={bEnable} // Bind checkbox to bEnable state
            onChange={handleToggle} // Update state on change
            disabled={loading} // Disable while loading
          />
            <label htmlFor="motionDetectionAlert" className={styles.toggleLabel}>
              <span className={styles.slider}></span>
            </label>
          </div>
        
        </div>

          <div className={styles.notificationItem}>
            <p>Push Notification</p>
            <div className={styles.toggleSwitch}>
              <input type="checkbox" id="pushNotification" className={styles.toggleInput} />
              <label htmlFor="pushNotification" className={styles.toggleLabel}>
                <span className={styles.slider}></span>
              </label>
            </div>
          </div>

          <p>Device Settings</p>

          <div className={styles.notificationItem}>
            <p>Camera Name</p>
            <div className={styles.inputContainer}>
              <input type="text" id="cameraName" className={styles.inputField} />
            </div>
          </div>

          <div className={styles.notificationItem}>
            <p>Screen and Sound</p>
            <div className={styles.selectContainer}>
              <select id="screenSound" className={styles.selectInput}>
                <option value="uninterruptedRecording">Uninterrupted recording</option>
                <option value="interruptedRecording">Interrupted recording</option>
              </select>
            </div>
          </div>

          <div className={styles.notificationItem}>
            <p>PTZ Control</p>
            <div className={styles.toggleSwitch}>
              <input type="checkbox" id="ptzControl" className={styles.toggleInput} />
              <label htmlFor="ptzControl" className={styles.toggleLabel}>
                <span className={styles.slider}></span>
              </label>
            </div>
          </div>

          <div className={styles.notificationItem}>
            <p>Storage Settings</p>
            <div className={styles.selectContainer}>
              <select id="storageSettings" className={styles.selectInput}>
                <option value="autoSaveToCloud">Auto Save to Cloud</option>
                <option value="localSaveOnly">Local Save Only</option>
              </select>
            </div>
          </div>
         

          <div className={styles.footer}>
            <button style={{ color: 'red' }} onClick={reboot}>Reboot</button>
          </div>
        </div>
    );

}

export default General;
