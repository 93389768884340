import React from 'react';
import styles from './LoginPage.module.css';

function SocialLogin() {
  return (
    <div className={styles.socialLogin}>
      <button className={styles.googleLogin}>
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/12f4eaa1f2cf7c174cd49cb9388172a37b4bd8c2c16637cf5bee563e8de16ab3?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1" alt="Google logo" className={styles.googleIcon} />
        <span>Sign in with Google</span>
      </button>
    </div>
  );
}

export default SocialLogin;