import React from 'react';
import DeviceList from './DeviceList';
import styles from './Dashboard.module.css';
import WebSocketComponent from '../../components/WebSocketComponent';

function Dashboard() {
  return (
    <div className={styles.dashboardContainer}>
      <main className={styles.mainContent}>
    <WebSocketComponent position='bottom-left'/>
        
        <DeviceList />
      </main>
    </div>
  );
}

export default Dashboard;