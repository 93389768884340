import React, { useEffect } from "react";
import styles from './MultiScreenDesktop.module.css';
import Player from "../../components/Player/Player";
// import JessibucaPlayer from "react-jessibuca"

function ScreenCard({ image, title,deviceid,plan }) {
  useEffect(() => {
    const changeHoverText = () => {
      const elements = document.querySelectorAll('.icon-title-tips .icon-title');
      elements.forEach(element => {
        if (element.textContent === '暂停') {
          element.textContent = 'Pause';
        }
        if (element.textContent === '截图') {
          element.textContent = 'ScreenSort';
        }
        if (element.textContent === '录制') {
          element.textContent = 'Video';
        }
        if (element.textContent === '全屏') {
          element.textContent = 'FullScreen';
        }
        if (element.textContent === '退出全屏') {
          element.textContent = 'Exit';
        }
        if (element.textContent === '播放') {
          element.textContent = 'Play';
        }
        if (element.textContent === '停止录制') {
          element.textContent = 'Stop Recording';
        }
        // 退出全屏
      });
    };

    // Create a MutationObserver to monitor changes in the DOM
    const observer = new MutationObserver(() => {
      changeHoverText();
    });

    // Observe the body for changes in its child elements
    observer.observe(document.body, { childList: true, subtree: true });

    // Initial run to catch elements already in the DOM
    changeHoverText();

    // Cleanup the observer when the component is unmounted
    return () => observer.disconnect();
  }, []);
  return (
    <div className={styles.screenCard}>
      <div className={styles.imageWrapper}>
        <Player 
          playUrl={plan === "LIVE" ? `https://${deviceid}.torqueverse.dev/flv/live_ch0_0.flv?verify=a/b4Znt+OFGrYtmHw0T16Q==`:
          `https://media.arcisai.io:8443/hdl/${plan}/RTSP-${deviceid}.flv`   
          }
          className={styles.imageWrapper}
            />
      {/* <JessibucaPlayer
          controls={true}
          loadingText={'loading'}
          // url={selectedDevice.planname === "LIVE" ? 
          //   `https://${selectedDevice.deviceid}.torqueverse.dev/flv/live_ch0_0.flv?verify=a/b4Znt+OFGrYtmHw0T16Q==`
          //  : `https://media.arcisai.io:8443/hdl/${selectedDevice.planname}/RTSP-${selectedDevice.deviceid}.flv`}
          src={plan === "LIVE" ? `https://${deviceid}.torqueverse.dev/flv/live_ch0_0.flv?verify=a/b4Znt+OFGrYtmHw0T16Q==`:
              `https://media.arcisai.io:8443/hdl/${plan}/RTSP-${deviceid}.flv`   
              }
          decoder="/decoder.js"
          style={{ width: '100%', height: '100%' }}
          // objectFit={"fill"}
          
        /> */}
        {/* <img src={image} alt={title} className={styles.screenImage} /> */}
        <span className={styles.connectionStatus}>connected</span>
        {/* <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/d38bab563bc522e6a141d3c7850214afcce35436aa844c0cee821126b2ee5ab3?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1" alt="" className={styles.overlayIcon} /> */}
      </div>
      <div className={styles.cardFooter}>
        <h2 className={styles.cardTitle}>{title}</h2>
        
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/d921992153381825fa43573f2e98108bb8b64b895aa842713dc3d78bae08a673?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1" alt="More options" className={styles.moreIcon} />
      </div>
    </div>
  );
}

export default ScreenCard;