import React, { useEffect, useState } from "react";
import axios from "axios";

import styles from "../Settings.module.css";
function DeviceInfo({ deviceId }) {
  const [deviceinfo, setDeviceInfo] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://adiance-portal-backend-7d9tj.ondigitalocean.app/p2p/deviceinfo",
          {
            params: {
              deviceid: deviceId,
            },
          }
        );
        setDeviceInfo(response.data);
      } catch (error) {
        setError(error);
      }
    };

    fetchData();
  }, []); // Empty dependency array means this effect runs once after the initial render

  return (
    <div className={styles.notify}>
      <div
        className={styles.checkboxContainer}
        style={{ marginBottom: "16px",color:'#7F56D9' }}
      >
        <label style={{ fontSize: "18px", fontWeight: "bold" }}>Device Info</label>
      </div>

      <div style={{ marginBottom: "12px", display: "flex", alignItems: "center" }}>
        <label
          htmlFor="deviceName"
          style={{ flex: "0 0 150px", marginRight: "16px", fontWeight: "bold" }}
        >
          Device Name
        </label>
        <input
          type="text"
          id="deviceName"
          value={deviceinfo.deviceName}
          disabled
          style={{
            border: "1px solid #ccc",
            padding: "8px",
            borderRadius: "4px",
            fontSize: "14px"
          }}
        />
      </div>

      <div style={{ marginBottom: "12px", display: "flex", alignItems: "center" }}>
        <label
          htmlFor="deviceModel"
          style={{ flex: "0 0 150px", marginRight: "16px", fontWeight: "bold" }}
        >
          Device Model
        </label>
        <input
          type="text"
          id="deviceModel"
          value={deviceinfo.model}
          disabled
          style={{
            border: "1px solid #ccc",
            padding: "8px",
            borderRadius: "4px",
            fontSize: "14px"
          }}
        />
      </div>

      <div style={{ marginBottom: "12px", display: "flex", alignItems: "center" }}>
        <label
          htmlFor="deviceVersion"
          style={{ flex: "0 0 150px", marginRight: "16px", fontWeight: "bold" }}
        >
          Device Version
        </label>
        <input
          type="text"
          id="deviceVersion"
          value={deviceinfo.hardwareVersion}
          disabled
          style={{
            border: "1px solid #ccc",
            padding: "8px",
            borderRadius: "4px",
            fontSize: "14px"
          }}
        />
      </div>

      <div style={{ marginBottom: "12px", display: "flex", alignItems: "center" }}>
        <label
          htmlFor="softwareVersion"
          style={{ flex: "0 0 150px", marginRight: "16px", fontWeight: "bold" }}
        >
          Software Version
        </label>
        <input
          type="text"
          id="softwareVersion"
          value={deviceinfo.firmwareVersion}
          disabled
          style={{
            border: "1px solid #ccc",
            padding: "8px",
            borderRadius: "4px",
            fontSize: "14px"
          }}
        />
      </div>

      <div style={{ marginBottom: "12px", display: "flex", alignItems: "center" }}>
        <label
          htmlFor="releaseTime"
          style={{ flex: "0 0 150px", marginRight: "16px", fontWeight: "bold" }}
        >
          Release Time
        </label>
        <input
          type="text"
          id="releaseTime"
          value={deviceinfo.firmwareReleaseDate}
          disabled
          style={{
          
            border: "1px solid #ccc",
            padding: "8px",
            borderRadius: "4px",
            fontSize: "14px"
          }}
        />
      </div>
    </div>
  );
}

export default DeviceInfo;
