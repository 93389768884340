import React, { useState } from 'react';
import styles from './Dashboard.module.css';
import SdIcon from './file-video-2.png';
import SDCardDataTable from '../../components/SDCardDataTable';
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import joystick from '../../assets/images/joystick.png'
function Controls({ device ,onFullscreen,onRecording,isRecording ,onScreenshot}) {
  const [isSdCardModalOpen, setIsSdCardModalOpen] = useState(false);
  const [sdCardData, setSDCardData] = useState(null);

  const handleOpenSdCardModal = () => {
    setIsSdCardModalOpen(true);
  };

  const handleCloseSdCardModal = () => {
    setIsSdCardModalOpen(false);
  };

  const handlePtzControlClick = () => {
    // Select the element with the class 'jessibuca-ptz-controls'
    const ptzElement = document.querySelector('.jessibuca-ptz-controls');
    
    if (ptzElement) {
      // Toggle the 'jessibuca-ptz-controls-show' class
      ptzElement.classList.toggle('jessibuca-ptz-controls-show');
    }
  };

  const controlButtons = [
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/70584ed7abde56267235311c559a0967f292887f91f4db993051dcb0aa4e9d3b?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1", label: "Previous" },
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/eb13bad23cc56caf6f39583283b9581e6547c374aae912ed488cd07b1f97e1f7?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1", label: "Rewind" },
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/2548564e-2d37-41ed-aab1-8767617cf98e?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1", label: "Play/Pause" },
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/2e349603dee4e21eaaf993de1d2862b20f53342dc9ae776d5a34a9a972d707c8?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1", label: "Forward" }
  ];

  const actionButtons = [
    { icon: SdIcon, label: "SD Card", onClick: handleOpenSdCardModal },
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/3ab7aa5bf362fc891e934e1cd86d7ac7f25e392cc872286f7576bff43a78e84b?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1", label: "Take Snapshot",onClick:onScreenshot },
    {
      icon: isRecording
        ? "https://cdn.builder.io/api/v1/image/assets/TEMP/a8113056d1c5bfa925c3aaa90d97025f6adbccf1cae2dbfa2b693258a22f6b8b?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1"
        : "https://cdn.builder.io/api/v1/image/assets/TEMP/a8113056d1c5bfa925c3aaa90d97025f6adbccf1cae2dbfa2b693258a22f6b8b?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1", // replace with an alternative URL if needed
      label: isRecording ? "Stop Recording" : "Record Video",
      onClick: onRecording
    },
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/9a3ab3d8a10f9301c69a1ce55f89f525f3e3fe696ef75a4924ed9e05eec16c3b?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1", label: "Mute Audio" },
    { icon: joystick, label: "PTZ Control" ,onClick: handlePtzControlClick},
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/a73a33f8ddfa7ead167c5c113244961e26c3af2e4e3e2fda8a031ef97b2bad82?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1", label: "Full Screen",onClick:onFullscreen },
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/1b66f0f6fc9fed2fd34414a7d90d82cccd7005ab29cc52988915bf34bc200ac5?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1", label: "Download Clip" },
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/20ad34f6e0817d0c2612c98a5bfa1c8ca287c31e8c03fea4e5686c56c3da9f1a?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1", label: "AI Reports" },
   
  ];

  return (
    <>
      <div className={styles.controls}>
        {/* <div className={styles.playbackControls}>
          {controlButtons.map((button, index) => (
            <button key={index} aria-label={button.label} className={styles.controlButton}>
              <img src={button.icon} alt="" className={styles.controlIcon} />
            </button>
          ))}
        </div> */}
        <div className={styles.actionButtons}>
          {actionButtons.map((button, index) => (
            <button key={index} className={styles.actionButton} onClick={button.onClick}>
              <img src={button.icon} alt="" className={styles.actionIcon} />
              <span className={styles.actionLabel}>{button.label}</span>
            </button>
          ))}
        </div>
      </div>
      <Modal
        isOpen={isSdCardModalOpen}
        onClose={handleCloseSdCardModal}
        size="5xl"
      >
        <ModalOverlay />
        <ModalContent bg="white" color="black">
          <ModalHeader>SD Card</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SDCardDataTable data={sdCardData} deviceId={device.deviceid} />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" onClick={handleCloseSdCardModal}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Controls;
