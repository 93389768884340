import React, { useEffect, useRef, useState } from 'react';
import JessibucaPlayer from 'react-jessibuca';
import styles from './Dashboard.module.css';
import axios from 'axios';
import Controls from '../../pages/SingleDevice/Controls';

const Player = ({ device,playUrl, className, style ,showControls }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const jessibucaRef = useRef(null);
    const containerRef = useRef(null);
    const playerRef = useRef(null); // For JessibucaPlayer reference
    const [error, setError] = useState(null);
    const showOperateBtns = true;
    const forceNoOffscreen = false;

    useEffect(() => {
        // Initialize Jessibuca player on mount if URL contains "hdl"
        if (playUrl && playUrl.includes("hdl")) {
            create();
        }

        // Cleanup Jessibuca instance on unmount
        return () => {
            destroy();
        };
    }, []);

    useEffect(() => {
        const handlePlayUrlChange = async () => {
            if (jessibucaRef.current) {
                await destroy(); // Destroy the existing instance first
            }
            if (playUrl) {
                if (playUrl.includes("hdl")) {
                    create();
                    play();
                }
            }
        };

        handlePlayUrlChange();
    }, [playUrl]);

    const create = () => {
        if (!containerRef.current) return;

        jessibucaRef.current = new window.JessibucaPro({
            container: containerRef.current,
            decoder: '/js/decoder-pro.js',
            useMse: true,
            videoBuffer: 0.2, // Buffer length
            isResize: false,
            text: "ArcisAI",
            loadingText: "Loading",
            debug: false,
            debugLevel: 'debug',
            showBandwidth: showOperateBtns, // Show bandwidth
            operateBtns: {
                ptz: showOperateBtns,
                fullscreen: showOperateBtns,
                screenshot: showOperateBtns,
                play: showOperateBtns,
                audio: showOperateBtns,
                record: showOperateBtns,
            },
            forceNoOffscreen: forceNoOffscreen,
            isNotMute: false,
        });

        if (jessibucaRef.current.on) {
            
            jessibucaRef.current.on('ptz', (arrow) => {
                // console.log(device.deviceid)
                const ptzParams = {
                    '-step': 0,
                    '-act': arrow,
                    '-speed': 3,
                    '-presetNUM': 1,
                    deviceId: `${device.deviceid}.torqueverse.dev` // Replace with actual deviceId
                  };
                  const authHeader = 'Basic ' + btoa(`admin:`);
                  const response =  axios.post('https://adiance-portal-backend-7d9tj.ondigitalocean.app/p2p/ptz', ptzParams, {
                    headers: {
                      'Content-Type': 'application/json',
                      'Authorization': authHeader
                    }
                  });
                //   console.log('PTZ Status:', response.data);
                // console.log('ptz', arrow);
            });
        } else {
            console.warn('JessibucaPro does not support event listeners in this way.');
        }
    };

  
    const play = () => {
        if (jessibucaRef.current && playUrl) {
            // Play the new video URL
            jessibucaRef.current.play(playUrl);
            setIsPlaying(true);
        }
    };

    const pause = () => {
        if (jessibucaRef.current) {
            jessibucaRef.current.pause();
            setIsPlaying(false);
        }
    };

    const destroy = async () => {
        if (jessibucaRef.current) {
            await jessibucaRef.current.destroy(); // Properly destroy the instance
            jessibucaRef.current = null; // Clear the reference
            setIsPlaying(false);
        }
    };

    const handleFullscreen = async () => {
        try {
          if (jessibucaRef.current) {
            // Assuming setFullscreen does not return a promise
            jessibucaRef.current.setFullscreen(true);
          }
        } catch (error) {
          setError('Fullscreen failed: ' + error.message);
        }
      };
    
      const handleRecording = async () => {
        try {
          if (jessibucaRef.current) {
            if (isRecording) {
              await jessibucaRef.current.stopRecordAndSave();
              setIsRecording(false);
            } else {
              await jessibucaRef.current.startRecord();
              setIsRecording(true);
            }
          }
        } catch (error) {
          setError('Recording failed: ' + error.message);
        }
      };

      const handleScreenshot = () => {
        try {
          if (jessibucaRef.current) {
            const file = jessibucaRef.current.screenshot('test', 'blob');
            const url = URL.createObjectURL(file);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'screenshot.png'; // Set desired filename
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
          } else {
            console.warn('Player is not initialized');
          }
        } catch (error) {
          console.error('Screenshot failed:', error.message);
          setError('Screenshot failed: ' + error.message);
        }
      };

    return (
        <>
        
            {playUrl && playUrl.includes("hdl") ? (
                // Render JessibucaPro style={{ display: "flex", justifyContent: "center" }}
                <div className="container-shell"  >
                    <div
                        id="container"
                        ref={containerRef}
                        className={className}
                        style={style}
                    ></div>
                </div>
            ) : playUrl && playUrl.includes("record") ? (
                <div style={{ position: 'relative', width: '1220px', height: '720px' }}>
                    <video
                        style={{ width: '100%', height: '100%' }}
                        // controls
                        autoPlay
                        muted
                        src={playUrl}
                    />
                 </div>
            
            ) : (
                // Render JessibucaPlayer as a fallback for other URLs
                <JessibucaPlayer
                    ref={containerRef}
                    style={{ width: '1220px', height: '720px' }}
                    className={styles.deviceImage}
                    controls={true}
                    loadingText="loading"
                    src={playUrl}
                    decoder="/decoder.js"
                />
          
            )}

            {showControls && (
                <Controls
                    device={device}
                    onFullscreen={handleFullscreen}
                    onScreenshot={handleScreenshot}
                    onRecording={handleRecording}
                    isRecording={isRecording}
                />
            )}  

        </>
    );
};

export default Player;
