import React, { useEffect, useState } from 'react';
import styles from './EventFilter.module.css';
import { getCustomerCameraList } from '../../services/getcamera';

const fetchData = async (page, updateOnlyStatus = false) => {
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const customerId = userDetails.customerid;
  let resultPerPage = 900;
  if (window.innerWidth > 1645) {
    resultPerPage = 1200; // Set to 12 if the screen width is greater than 1645px
  }

  try {
    // Fetch status data
    const statusResponse = await fetch('https://adiance-portal-backend-7d9tj.ondigitalocean.app/status');
    const statusData = await statusResponse.json();

    if (!updateOnlyStatus) {
      // Fetch camera list data
      const result = await getCustomerCameraList(customerId, page, resultPerPage);
      if (result.success) {
        // Merge the status data with camera list data
        const updatedCameraList = result.cameras.map(camera => {
          const matchingStatus = statusData.proxies.find(proxy => 
            proxy.name.trim().toLowerCase() === camera.deviceid.trim().toLowerCase()
          );
          return {
            ...camera,
            status: matchingStatus && matchingStatus.status === 'online' ? 'connected' : 'disconnected' // Add status field or default to 'unknown'
          };
        });
        return updatedCameraList;
      }
    }
  } catch (error) {
    console.error('Error fetching camera list:', error);
  }
  return [];
};

const EventFilter = ({onAiSelect,onPageChange}) => {
  const [devices, setDevices] = useState([]);
  const [selectedAi, setSelectedAi] = useState("0x2");
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    const loadDevices = async () => {
      const data = await fetchData(1);
      setDevices(data);
    };

    loadDevices();

    // Update status periodically
    const statusInterval = setInterval(() => {
      fetchData(currentPage, true).then((data) => {
        setDevices((prevDevices) =>
          prevDevices.map((device) => {
            const updatedDevice = data.find((d) => d.deviceid === device.deviceid);
            return updatedDevice ? { ...device, status: updatedDevice.status } : device;
          })
        );
      });
    }, 5000);

    return () => clearInterval(statusInterval);
  }, [currentPage]);
  
  const handleAiSelect = (event) => {
    const selectedAi = event.target.value;
    setSelectedAi(selectedAi); // Update the state
    setCurrentPage(1)
    onAiSelect(selectedAi);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1)); // Prevent going below page 1
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1); // Increment page number
  };

  useEffect(() => {
    if (onPageChange) {
      onPageChange(currentPage);
    }
  }, [currentPage, onPageChange]);

  return (
    <div className={styles.filterWrapper}>
      <div className={styles.deviceFilter}>
        <label htmlFor="deviceSelect" className={styles.visuallyHidden}>Select Device</label>
      </div>
      <div className={styles.modelFilter}>
        <label htmlFor="modelSelect" className={styles.visuallyHidden}>Select Detection Model</label>
        <select id="modelSelect" className={styles.select} value={selectedAi} onChange={handleAiSelect}>
          {/* <option>All detection models</option> */}
          <option value="0x2">Motion Detection</option>
          <option value="0x10">Human Detection</option>
          <option value="0x20">Face Detection</option>
          <option value="0x2000">LineCrossing</option>
          <option value="0x4000">Region Enter</option>
          <option value="0x8000">Region Exit</option>
          <option value="0x10000">Intrude</option>
          <option value="0x20000">Un-Attended</option>
          <option value="0x40000">Object Remove</option>
        </select>
        <button className={styles.clearButton} onClick={() => setSelectedAi('MOTION')}>Clear</button>
      </div>
      <div className={styles.buttonGroup}>
      <button className={styles.prevWeek} onClick={handlePrevPage}>Previous</button>
      <button className={styles.nextWeek} onClick={handleNextPage}>Next</button>
      </div>
    </div>
  );
};

export default EventFilter;
