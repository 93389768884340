import React from "react";
import LocalSetup from "./Network-Setting/LocalSetup";
import styles from "./Settings.module.css";
import Time from "./System-Setting/Time";
import DeviceInfo from "./System-Setting/DeviceInfo";

function SystemSetting({ selectedSystemSetting, deviceId, onSave }) {
  return (
    <div className={styles.mediaContent}>
      {selectedSystemSetting === "Time" && <Time deviceId={deviceId} />}
      {selectedSystemSetting === "Device Info" && <DeviceInfo deviceId={deviceId} />}
    </div>
  );
}

export default SystemSetting;
